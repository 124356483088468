<template>
  <div class="app-pagination" v-if="total">
    <b-pagination
      :total="total"
      :current="current"
      :range-before="rangeBefore"
      :range-after="rangeAfter"
      :order="order"
      :size="size"
      :simple="isSimple"
      :rounded="isRounded"
      :per-page="perPage"
      :icon-prev="prevIcon"
      :icon-next="nextIcon"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      @update:current="value => $emit('update:current', value)"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: "app-pagination",

  props: {
    current: {
      type: Number,
      default: 1
    },

    total: {
      type: Number,
      default: 0
    },

    perPage: {
      type: Number,
      default: 10
    }
  },

  data() {
    return {
      // total: 200,
      // current: 1,
      rangeBefore: 1,
      rangeAfter: 1,
      order: "is-centered",
      size: "is-small",
      isSimple: false,
      isRounded: false,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right"
    };
  }
};
</script>

<style lang="scss" >
.app-pagination {
  .pagination-previous,
  .pagination-next,
  .pagination-link {
    background-color: #2d2c33;
    color: #fff;

    border: 1px solid #2d2c33 !important;
  }

  .pagination-link.is-current {
    color: #23b657;
    border: 1px solid #23b657 !important;
  }
}
// .pagination {
// width: 100%
// }
</style>

