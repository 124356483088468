import { ApiService } from "./api.service";
// const BASE_URL = "https://api.plazalab.club/";
import { BASE_URL } from "@/utils/constants";

class BillingError extends Error {
  constructor(message, errorCode, statusText) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
    this.errorText = statusText;
  }
}

const BillingService = {
  getTransactions: async function({ limit, offset }) {
    const requestData = {
      method: "get",
      url: `${BASE_URL}api/storage/transactions/`,
      params: {
        limit,
        offset
      }
    };

    try {
      const response = await ApiService.customRequest(requestData);

      return response.data;
    } catch (error) {
      throw new BillingError(
        error.response.data,
        error.response.status,
        error.response.statusText
      );
    }
  }
};

export default BillingService;

export { BillingService, BillingError };
