<template>
  <div class="transactions-page">
    <div class="transactions-table">
      <div class="transactions-table__header">
        <span
          class="transactions-table__cell transactions-table__header-cell transactions-table__cell_date"
        >Date</span>
        <span
          class="transactions-table__cell transactions-table__header-cell transactions-table__cell_amount"
        >Amount</span>
        <span
          class="transactions-table__cell transactions-table__header-cell transactions-table__cell_object"
        >Object</span>
        <span
          class="transactions-table__cell transactions-table__header-cell transactions-table__cell_reason"
        >Reason</span>
      </div>
      <div class="transactions-table__body" ref="content">
        <div
          v-for="(transact, index) in transactionsOrders"
          :key="index"
          class="transactions-table__row"
        >
          <span
            class="transactions-table__cell transactions-table__cell_date"
          >{{ transact.created_at }}</span>
          <span
            :class="['transactions-table__cell', 'transactions-table__cell_amount', +transact.amount  < 0 ? 'red' : 'green']"
          >{{ transact.amount | renderAmount }}</span>
          <span
            class="transactions-table__cell transactions-table__cell_object"
          >{{ transact.order_info }}</span>
          <span
            class="transactions-table__cell transactions-table__cell_reason green"
          >{{ transact.gateway | renderReason }}</span>
        </div>
      </div>
    </div>

    <Pagination
      class="transactions-page__pagination"
      :total="count"
      :perPage="limit"
      :current.sync="currentPage"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

import { HistoryService } from "../services/history.services";
import { BillingService } from "../services/billing.service";

export default {
  components: {
    Pagination
  },

  filters: {
    renderAmount(value) {
      if (+value < 0) return `-€${value.split("-")[1]}`;
      else if (+value > 0) return `+€${value}`;

      return `€${value}`;
    },

    renderReason(value) {
      const res = {
        crypto: "CoinPayments",
        paypal: "PayPal",
        buy_order: "Buy Order",
        bonus: "Bonus",
        fee: "Fee"
      };

      return res[value] || value;
    }
  },

  data: () => ({
    transactionsData: [
      {
        date: "16.07.2019",
        time: "18:51",
        amount: "-€3.00",
        object: "(+43) 68184354085",
        reason: "Google"
      },
      {
        date: "10.07.2019",
        time: "17:00",
        amount: "€2.50",
        object: "Coin payments",
        reason: "Complete"
      },
      {
        date: "5.07.2019",
        time: "11:55",
        amount: "-€3.00",
        object: "Coin payments",
        reason: "Canceled"
      },
      {
        date: "30.06.2019",
        time: "22:35",
        amount: "-€2.50",
        object: "(+358) 6818435408",
        reason: "Incompleted order"
      }
    ],

    transactionsOrders: null,
    currentPage: 1,
    limit: 10,
    offset: 0,
    count: 0
  }),
  created() {
    // this.transactionsData = BillingService.getTransactions();
  },

  mounted() {
    this.$watch(
      "currentPage",
      next => {
        this.loadTransactions();
      },
      {
        immediate: true
      }
    );
  },

  methods: {
    async loadTransactions() {
      try {
        const response = await BillingService.getTransactions({
          limit: this.limit,
          offset: (this.currentPage - 1) * this.limit
        });

        this.count = response.count;

        this.transactionsOrders = response.results;
      } catch (error) {
        /* this.errorMessage = error.response
        this.showErrorMsg() */
        throw error;
      }
    }
  }
};
</script>


<style lang="scss" scoped>
.transactions-page {
  &__pagination {
    margin: 15px 0;
  }
}
</style>
