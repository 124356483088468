import { ApiService } from "./api.service";
// const BASE_URL = "https://api.plazalab.club/";
import { BASE_URL } from "@/utils/constants";

class HistoryError extends Error {
  constructor(message, errorCode, statusText) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
    this.errorText = statusText;
  }
}

const HistoryService = {
  getHistory: async params => {
    const requestData = {
      method: "get",
      url: `${BASE_URL}api/storage/sms/history/`,
      params
    };

    try {
      const response = await ApiService.customRequest(requestData);

      return response.data;
    } catch (error) {
      throw new HistoryError(
        error.response.data,
        error.response.status,
        error.response.statusText
      );
    }
  }
};

export default HistoryService;

export { HistoryService, HistoryError };
